import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1034.000000 959.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,959.000000) scale(0.100000,-0.100000)">

<path d="M0 4795 l0 -4795 5170 0 5170 0 0 4795 0 4795 -5170 0 -5170 0 0
-4795z m5400 4726 c0 -17 12 -19 140 -26 128 -7 140 -9 140 -26 0 -17 9 -19
95 -19 86 0 95 -2 95 -19 0 -21 40 -31 133 -31 49 0 57 -3 57 -19 0 -17 11
-20 90 -26 79 -6 90 -9 90 -26 0 -17 8 -19 70 -19 68 0 70 -1 70 -25 0 -23 4
-25 44 -25 51 0 78 -8 70 -21 -3 -5 -1 -9 5 -9 5 0 12 8 14 18 4 13 5 13 6 -4
1 -18 9 -23 46 -28 36 -6 45 -11 45 -27 0 -16 7 -19 50 -19 43 0 50 -3 50 -19
0 -16 9 -21 45 -27 35 -5 45 -10 45 -25 0 -16 8 -19 44 -19 39 0 74 -18 61
-32 -6 -5 1 -7 73 -14 47 -5 52 -8 52 -30 0 -19 5 -24 25 -24 18 0 25 -5 25
-20 0 -17 7 -20 45 -20 41 0 45 -2 45 -25 0 -23 4 -25 45 -25 41 0 45 -2 45
-25 0 -20 5 -25 25 -25 18 0 25 -5 25 -20 0 -15 -7 -20 -25 -20 -18 0 -25 5
-25 19 0 22 -35 31 -121 31 -52 0 -59 2 -59 20 0 18 -7 20 -74 20 -104 0 -166
10 -166 26 0 8 -1 15 -2 16 -2 0 -95 4 -208 7 -187 5 -205 8 -204 24 2 16 -15
17 -232 17 -227 0 -234 -1 -234 -20 0 -19 -7 -20 -199 -20 -210 0 -261 -5
-261 -25 0 -16 -54 -25 -152 -25 -71 0 -78 -2 -78 -20 0 -17 -8 -19 -72 -22
-67 -3 -73 -5 -76 -25 -3 -22 -8 -23 -93 -23 -80 0 -89 -2 -89 -19 0 -20 -39
-31 -112 -31 -31 0 -38 -4 -38 -20 0 -16 -7 -20 -34 -20 -62 0 -96 -11 -96
-31 0 -16 -8 -19 -50 -19 -42 0 -50 -3 -50 -19 0 -19 -39 -31 -102 -31 -21 0
-28 -5 -28 -19 0 -15 -10 -20 -50 -25 -41 -5 -50 -10 -50 -26 0 -17 -7 -20
-45 -20 -37 0 -45 -3 -45 -19 0 -15 -10 -20 -45 -25 -35 -6 -45 -11 -45 -26 0
-13 -8 -20 -27 -22 -20 -2 -29 -9 -31 -25 -3 -20 -9 -23 -48 -23 -37 0 -44 -3
-44 -19 0 -16 -9 -21 -45 -27 -34 -5 -45 -11 -45 -24 0 -13 -11 -19 -45 -24
-36 -6 -45 -11 -45 -27 0 -14 -7 -19 -25 -19 -18 0 -25 -5 -25 -19 0 -16 -9
-21 -45 -27 -35 -5 -45 -10 -45 -25 0 -14 -7 -19 -25 -19 -20 0 -25 -5 -25
-25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -14 0
-20 -7 -20 -24 0 -20 -6 -25 -45 -30 -36 -6 -45 -11 -45 -27 0 -14 -7 -19 -25
-19 -18 0 -25 -5 -25 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26 -25 -3 -17
-10 -23 -28 -23 -19 0 -24 -5 -24 -25 0 -18 -5 -25 -20 -25 -13 0 -20 -7 -20
-20 0 -15 -7 -20 -25 -20 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25 -25 -20 0
-25 -5 -25 -25 0 -18 -5 -25 -20 -25 -13 0 -20 -7 -20 -20 0 -15 -7 -20 -25
-20 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5 -25 -25 0 -18 -5
-25 -20 -25 -13 0 -20 -7 -20 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26
-25 -2 -15 -10 -23 -23 -23 -14 0 -19 -7 -19 -25 0 -18 -5 -25 -19 -25 -16 0
-21 -9 -27 -45 -5 -39 -10 -45 -30 -45 -19 0 -24 -5 -24 -25 0 -18 -5 -25 -20
-25 -14 0 -20 -7 -20 -21 0 -12 -8 -23 -19 -26 -13 -3 -22 -17 -26 -43 -5 -30
-12 -40 -28 -42 -16 -2 -23 -11 -25 -30 -2 -19 -9 -28 -22 -28 -13 0 -20 -10
-25 -39 -4 -27 -13 -41 -26 -44 -13 -3 -19 -14 -19 -31 0 -19 -5 -26 -19 -26
-16 0 -21 -9 -27 -45 -5 -34 -11 -45 -24 -45 -13 0 -19 -11 -24 -45 -6 -36
-11 -45 -27 -45 -16 0 -19 -7 -19 -45 0 -41 -2 -45 -25 -45 -23 0 -25 -4 -25
-45 0 -37 -3 -45 -19 -45 -14 0 -20 -10 -25 -44 -5 -32 -12 -45 -26 -49 -16
-4 -20 -14 -20 -51 0 -39 -3 -46 -19 -46 -15 0 -20 -10 -26 -50 -6 -40 -11
-50 -26 -50 -22 0 -24 -10 -6 -29 10 -11 10 -13 0 -7 -10 5 -13 -6 -13 -43 0
-44 -3 -50 -22 -53 -21 -3 -23 -9 -26 -70 -2 -58 -6 -68 -21 -68 -20 0 -31
-40 -31 -112 0 -31 -4 -38 -20 -38 -18 0 -20 -7 -22 -72 -1 -40 -2 -81 -1 -90
0 -10 -8 -18 -17 -18 -22 0 -30 -38 -30 -146 0 -75 -2 -84 -19 -84 -15 0 -19
-11 -24 -67 -4 -38 -7 -123 -7 -190 0 -116 -1 -123 -20 -123 -20 0 -20 -7 -20
-345 0 -338 0 -345 20 -345 19 0 20 -7 22 -147 0 -82 2 -152 3 -158 2 -5 3
-34 4 -62 1 -46 4 -53 21 -53 18 0 20 -7 20 -84 0 -108 7 -143 31 -149 16 -4
19 -15 19 -76 0 -64 2 -71 20 -71 17 0 20 -7 20 -52 0 -88 10 -128 31 -128 15
0 19 -8 19 -39 0 -67 11 -101 31 -101 16 0 19 -8 19 -50 0 -43 3 -50 20 -50
16 0 20 -7 20 -34 0 -61 11 -96 31 -96 14 0 19 -9 21 -48 3 -40 6 -47 26 -50
19 -3 22 -9 22 -48 0 -37 3 -44 19 -44 15 0 20 -10 26 -50 6 -40 11 -50 26
-50 15 0 19 -7 19 -39 0 -33 4 -40 23 -43 19 -3 22 -10 25 -51 3 -39 6 -47 23
-47 14 0 19 -7 19 -25 0 -20 5 -25 25 -25 23 0 25 -4 25 -45 0 -37 3 -45 19
-45 15 0 20 -10 25 -45 6 -36 11 -45 27 -45 14 0 19 -7 19 -25 0 -20 5 -25 25
-25 20 0 25 -5 25 -25 0 -18 5 -25 19 -25 16 0 21 -9 27 -45 5 -34 11 -45 24
-45 12 0 20 -9 22 -22 2 -15 11 -24 26 -26 14 -2 22 -10 22 -22 0 -10 9 -22
20 -25 11 -3 20 -15 20 -26 0 -13 7 -19 24 -19 20 0 25 -6 30 -45 6 -36 11
-45 27 -45 14 0 19 -7 19 -25 0 -20 5 -25 25 -25 20 0 25 -5 25 -25 0 -18 5
-25 20 -25 13 0 20 -7 20 -20 0 -15 7 -20 25 -20 20 0 25 -5 25 -25 0 -18 5
-25 20 -25 11 0 21 -4 21 -10 1 -5 2 -16 3 -22 0 -7 11 -14 24 -16 14 -2 22
-10 22 -23 0 -14 7 -19 25 -19 20 0 25 -5 25 -25 0 -20 5 -25 25 -25 20 0 25
-5 25 -25 0 -18 5 -25 20 -25 13 0 20 -7 20 -20 0 -15 7 -20 25 -20 20 0 25
-5 25 -25 0 -20 5 -25 25 -25 18 0 25 -5 25 -19 0 -16 9 -21 45 -27 35 -5 45
-10 45 -25 0 -12 7 -19 19 -19 13 0 21 -8 23 -22 2 -14 12 -24 28 -28 15 -4
24 -12 22 -22 -2 -12 10 -17 43 -22 36 -6 45 -11 45 -27 0 -14 7 -19 25 -19
20 0 25 -5 25 -25 0 -23 4 -25 44 -25 39 0 45 -3 48 -23 2 -14 11 -23 26 -25
14 -2 22 -10 22 -23 0 -16 7 -19 44 -19 39 0 45 -3 48 -22 2 -19 10 -24 46
-26 33 -3 42 -7 42 -22 0 -13 8 -20 28 -22 19 -2 28 -9 30 -25 3 -20 9 -23 48
-23 37 0 44 -3 44 -20 0 -11 5 -21 10 -21 6 -1 15 -3 20 -4 6 -1 21 -3 35 -4
18 -1 25 -7 25 -21 0 -16 7 -20 35 -21 58 -2 60 -3 63 -26 3 -21 9 -23 67 -23
58 0 64 -2 67 -23 3 -19 10 -22 51 -25 39 -3 47 -6 47 -23 0 -15 7 -19 33 -19
65 0 107 -12 105 -29 -3 -13 9 -16 70 -19 62 -2 72 -5 72 -21 0 -20 35 -31
100 -31 29 0 39 -4 43 -20 4 -17 14 -20 59 -20 75 0 128 -11 128 -25 0 -18 48
-25 166 -25 95 0 104 -2 104 -19 0 -14 10 -20 40 -25 23 -3 295 -6 605 -6 557
0 565 -1 565 -20 0 -17 -7 -20 -49 -20 -59 0 -81 -7 -81 -26 0 -8 -6 -15 -12
-16 -7 0 -17 -2 -23 -3 -5 -2 -22 -3 -37 -4 -21 -1 -28 -6 -28 -21 0 -16 -7
-20 -32 -20 -69 0 -108 -12 -108 -31 0 -17 -8 -19 -70 -19 -62 0 -70 -2 -70
-19 0 -19 -39 -31 -107 -31 -26 0 -33 -4 -33 -20 0 -18 -7 -20 -67 -21 -85 -2
-118 -10 -118 -29 0 -12 -18 -16 -92 -18 -85 -2 -93 -4 -93 -22 0 -17 -7 -20
-53 -20 -30 0 -102 -3 -160 -7 -95 -5 -107 -8 -107 -25 0 -17 -24 -18 -630
-18 -611 0 -630 1 -630 19 0 17 -12 19 -140 26 -128 7 -140 9 -140 26 0 17 -9
19 -95 19 -86 0 -95 2 -95 19 0 21 -40 31 -132 31 -51 0 -58 2 -58 20 0 16 -7
20 -32 20 -69 0 -108 12 -108 31 0 17 -8 19 -70 19 -62 0 -70 2 -70 19 0 20
-34 31 -101 31 -32 0 -39 3 -39 20 0 15 -7 20 -27 20 -64 0 -103 12 -103 31 0
16 -8 19 -50 19 -47 0 -50 2 -50 25 0 23 -4 25 -45 25 -37 0 -45 3 -45 19 0
14 -10 20 -44 25 -32 5 -45 12 -49 26 -4 16 -14 20 -51 20 -39 0 -46 3 -46 19
0 16 -9 21 -45 27 -34 5 -45 11 -45 24 0 13 -11 19 -45 24 -36 6 -45 11 -45
27 0 14 -7 19 -25 19 -18 0 -25 5 -25 19 0 16 -9 21 -45 27 -35 5 -45 10 -45
25 0 14 -7 19 -25 19 -18 0 -25 5 -25 19 0 16 -9 21 -44 26 -36 6 -46 12 -48
29 -2 13 -11 22 -25 24 -15 2 -23 10 -23 23 0 14 -8 19 -27 21 -16 0 -35 1
-43 2 -8 1 -16 11 -18 23 -3 17 -10 23 -28 23 -19 0 -24 5 -24 25 0 20 -5 25
-25 25 -18 0 -25 5 -25 19 0 15 -10 20 -45 25 -36 6 -45 11 -45 27 0 12 -7 19
-19 19 -13 0 -21 8 -23 22 -2 15 -11 24 -25 26 -15 2 -23 10 -23 23 0 14 -7
19 -25 19 -20 0 -25 5 -25 25 0 20 -5 25 -25 25 -18 0 -25 5 -25 20 0 13 -7
20 -20 20 -15 0 -20 7 -20 25 0 20 -5 25 -25 25 -18 0 -25 5 -25 19 0 13 -8
21 -23 23 -14 2 -23 11 -25 25 -2 14 -11 23 -25 25 -12 2 -23 9 -23 16 -1 6
-2 17 -3 22 0 6 -10 10 -21 10 -13 0 -20 7 -20 19 0 13 -8 21 -23 23 -14 2
-23 11 -25 26 -2 14 -10 22 -23 22 -14 0 -19 7 -19 25 0 20 -5 25 -25 25 -20
0 -25 5 -25 25 0 18 -5 25 -19 25 -13 0 -21 8 -23 23 -2 14 -11 23 -26 25 -14
2 -22 10 -22 23 0 12 -7 19 -19 19 -16 0 -21 9 -27 45 -5 34 -11 45 -24 45
-12 0 -20 9 -22 23 -2 14 -11 23 -25 25 -17 3 -23 10 -23 28 0 17 -6 24 -19
24 -16 0 -21 9 -27 45 -5 34 -11 45 -24 45 -12 0 -20 9 -22 23 -2 14 -11 23
-24 25 -16 2 -23 12 -28 41 -4 27 -12 40 -26 44 -14 3 -20 14 -20 31 0 19 -5
26 -19 26 -16 0 -21 9 -27 45 -5 35 -10 45 -25 45 -14 0 -19 7 -19 25 0 18 -5
25 -19 25 -16 0 -21 9 -27 45 -5 35 -10 45 -25 45 -16 0 -19 8 -19 45 0 41 -2
45 -25 45 -23 0 -25 4 -25 45 0 38 -3 45 -19 45 -15 0 -20 10 -26 50 -6 40
-11 50 -26 50 -16 0 -19 7 -19 45 0 41 -2 45 -25 45 -23 0 -25 3 -25 50 0 42
-3 50 -19 50 -20 0 -31 34 -31 96 0 27 -4 34 -20 34 -17 0 -20 7 -20 39 0 67
-11 101 -31 101 -17 0 -19 8 -19 70 0 62 -2 70 -19 70 -19 0 -31 39 -31 108 0
25 -4 32 -20 32 -18 0 -20 7 -20 58 0 92 -10 132 -31 132 -17 0 -19 9 -19 95
0 86 -2 95 -19 95 -17 0 -19 12 -26 141 -7 126 -9 140 -25 136 -16 -4 -17 19
-23 307 -4 171 -4 466 0 654 6 315 8 342 23 342 16 0 19 17 25 140 7 128 9
140 26 140 17 0 19 8 19 89 0 78 2 89 18 94 22 5 32 45 32 128 0 52 2 59 20
59 16 0 20 7 20 33 0 56 12 107 25 107 14 0 25 44 25 101 0 31 4 39 19 39 20
0 31 34 31 101 0 32 3 39 20 39 16 0 20 7 20 34 0 62 11 96 31 96 16 0 19 8
19 49 0 44 3 50 23 53 18 2 23 10 25 46 3 33 7 42 22 42 14 0 20 11 25 50 6
40 11 50 26 50 16 0 19 7 19 45 0 38 3 45 19 45 16 0 21 9 27 45 5 34 11 45
24 45 13 0 19 11 24 45 6 36 11 45 27 45 14 0 19 7 19 30 0 23 5 30 19 30 15
0 21 10 26 40 5 29 12 40 25 40 13 0 19 11 24 45 6 36 11 45 27 45 13 0 19 7
19 24 0 18 6 25 23 28 16 2 23 11 25 31 2 19 9 27 23 27 16 0 19 7 19 40 0 36
3 40 25 40 20 0 25 5 25 25 0 18 5 25 19 25 13 0 21 8 23 23 2 14 11 23 25 25
14 2 22 10 21 20 -4 55 2 72 27 72 20 0 25 5 25 25 0 20 5 25 25 25 18 0 25 5
25 20 0 13 7 20 20 20 15 0 20 7 20 25 0 20 5 25 25 25 20 0 25 5 25 25 0 18
5 25 19 25 13 0 21 8 23 23 2 14 11 23 25 25 15 2 23 10 23 23 0 12 7 19 19
19 16 0 21 9 26 44 7 43 8 44 51 51 35 5 44 10 44 26 0 12 7 19 19 19 13 0 21
8 23 23 2 14 11 23 25 25 14 2 23 11 25 25 2 14 11 23 25 25 15 2 23 10 23 23
0 14 7 19 25 19 18 0 25 5 25 19 0 13 8 21 23 23 12 2 23 9 23 16 1 6 2 17 3
22 0 6 10 10 21 10 15 0 20 7 20 25 0 20 5 25 25 25 18 0 25 5 25 19 0 15 10
20 45 25 36 6 45 11 45 27 0 14 7 19 25 19 20 0 25 5 25 25 0 20 5 25 25 25
18 0 25 5 25 19 0 16 9 21 45 27 35 5 45 10 45 25 0 14 7 19 25 19 18 0 25 5
25 19 0 16 9 21 45 27 34 5 45 11 45 25 0 13 11 19 45 25 35 5 45 10 45 25 0
14 7 19 25 19 18 0 25 5 25 19 0 16 9 21 45 27 34 5 45 11 45 24 0 13 11 19
45 24 36 6 45 11 45 27 0 16 7 19 45 19 38 0 45 3 45 19 0 15 10 20 50 26 40
6 50 11 50 26 0 14 8 19 28 21 15 0 34 1 42 2 8 1 16 11 18 24 3 19 9 22 53
22 41 0 49 3 49 19 0 20 34 31 96 31 27 0 34 4 34 20 0 17 7 20 39 20 67 0
101 11 101 31 0 16 8 19 50 19 42 0 50 3 50 19 0 17 11 20 90 26 79 6 90 9 90
26 0 17 8 19 67 19 49 0 72 5 86 18 12 10 17 11 12 3 -6 -10 -4 -12 4 -7 7 5
10 14 7 22 -4 11 14 14 99 14 97 0 105 2 105 19 0 18 11 20 140 26 128 7 140
9 140 26 0 18 21 19 625 19 606 0 625 -1 625 -19z m1043 -727 c51 -5 77 -11
77 -19 0 -18 58 -25 228 -25 143 0 152 -1 152 -19 0 -17 12 -20 135 -26 123
-7 135 -9 135 -26 0 -17 8 -19 71 -19 61 0 72 -3 76 -19 6 -22 41 -31 124 -31
51 0 59 -3 59 -19 0 -19 39 -31 108 -31 25 0 32 -4 32 -20 0 -17 7 -20 46 -20
37 0 47 -4 51 -19 5 -20 41 -31 99 -31 27 0 34 -4 34 -19 0 -15 10 -20 50 -26
40 -6 50 -11 50 -26 0 -16 7 -19 45 -19 38 0 45 -3 45 -19 0 -16 9 -21 45 -27
34 -5 45 -11 45 -24 0 -13 11 -19 45 -24 36 -6 45 -11 45 -27 0 -16 7 -19 44
-19 39 0 45 -3 48 -22 2 -17 11 -24 31 -26 18 -2 27 -9 27 -22 0 -13 10 -20
39 -25 27 -4 41 -13 44 -26 3 -13 14 -19 31 -19 19 0 26 -5 26 -19 0 -15 10
-21 40 -26 30 -5 40 -11 40 -26 0 -13 7 -19 24 -19 18 0 25 -6 28 -22 2 -19
10 -24 45 -26 34 -3 42 -7 45 -25 2 -14 11 -23 26 -25 14 -2 22 -10 22 -23 0
-14 7 -19 25 -19 20 0 25 -5 25 -25 0 -20 5 -25 25 -25 18 0 25 -5 25 -19 0
-13 8 -21 23 -23 14 -2 23 -11 25 -25 2 -15 10 -23 23 -23 12 0 19 -7 19 -19
0 -16 9 -21 45 -27 39 -5 45 -10 45 -30 0 -17 6 -24 19 -24 13 0 21 -8 23 -22
2 -15 11 -24 26 -26 14 -2 22 -10 22 -23 0 -10 4 -19 9 -19 5 0 8 -8 7 -17 -2
-11 5 -20 18 -23 12 -3 22 -15 24 -28 2 -14 10 -22 23 -22 14 0 19 -7 19 -25
0 -20 5 -25 25 -25 18 0 25 -5 25 -20 0 -13 7 -20 20 -20 15 0 20 -7 20 -25 0
-20 5 -25 25 -25 20 0 25 -5 25 -25 0 -18 5 -25 19 -25 15 0 20 -10 25 -45 6
-35 11 -45 26 -45 12 0 20 -8 22 -22 2 -15 11 -24 26 -26 16 -3 22 -10 22 -28
0 -17 6 -24 19 -24 15 0 21 -10 26 -40 5 -30 11 -40 26 -40 14 0 19 -7 19 -30
0 -23 5 -30 19 -30 16 0 21 -9 27 -45 5 -34 11 -45 25 -45 13 0 19 -10 24 -44
6 -37 10 -43 26 -39 16 4 19 0 19 -26 0 -24 4 -31 19 -31 16 0 21 -9 27 -45 5
-35 10 -45 25 -45 16 0 19 -8 19 -45 0 -41 2 -45 25 -45 23 0 25 -3 25 -50 0
-42 3 -50 19 -50 20 0 31 -34 31 -96 0 -27 4 -34 19 -34 15 0 20 -10 26 -50 6
-40 11 -50 26 -50 17 0 19 -8 19 -66 0 -56 3 -67 19 -71 20 -5 31 -41 31 -104
0 -32 3 -39 20 -39 16 0 20 -7 20 -32 0 -69 12 -108 31 -108 17 0 19 -9 19
-115 0 -105 2 -115 19 -115 15 0 19 -11 24 -67 4 -38 7 -89 7 -115 0 -40 3
-48 18 -48 18 0 19 -18 26 -302 4 -166 4 -438 0 -605 -7 -285 -8 -303 -26
-303 -15 0 -18 -8 -18 -43 0 -24 -3 -78 -6 -120 -6 -66 -10 -77 -25 -77 -17 0
-19 -9 -19 -95 0 -86 -2 -95 -19 -95 -22 0 -31 -35 -31 -121 0 -52 -2 -59 -20
-59 -16 0 -20 -7 -20 -32 0 -69 -12 -108 -31 -108 -17 0 -19 -8 -19 -69 0 -65
-2 -70 -22 -73 -13 -2 -23 -10 -24 -18 -1 -8 -2 -27 -2 -42 -2 -21 -7 -28 -22
-28 -17 0 -20 -7 -20 -44 0 -25 -4 -47 -9 -50 -5 -3 -7 -15 -4 -26 4 -15 0
-20 -16 -20 -18 0 -21 -6 -21 -45 0 -41 -2 -45 -25 -45 -23 0 -25 -4 -25 -45
0 -38 -3 -45 -20 -45 -15 0 -20 -7 -20 -26 0 -17 -6 -28 -20 -31 -14 -4 -21
-17 -26 -49 -5 -34 -11 -44 -25 -44 -13 0 -19 -11 -25 -45 -5 -35 -10 -45 -25
-45 -14 0 -19 -7 -19 -25 0 -18 -5 -25 -19 -25 -16 0 -21 -9 -27 -45 -5 -39
-10 -45 -30 -45 -19 0 -24 -5 -24 -25 0 -18 -5 -25 -19 -25 -16 0 -20 -8 -23
-42 -2 -36 -7 -44 -25 -46 -17 -3 -23 -10 -23 -28 0 -17 -6 -24 -19 -24 -13 0
-21 -8 -23 -22 -2 -15 -11 -24 -25 -26 -19 -3 -23 -10 -23 -43 0 -35 -3 -39
-25 -39 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5 -25 -25 0
-18 -5 -25 -20 -25 -13 0 -20 -7 -20 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11
-26 -25 -2 -15 -10 -23 -22 -23 -12 0 -20 -8 -22 -22 -2 -15 -11 -24 -25 -26
-14 -2 -23 -11 -25 -25 -2 -14 -11 -23 -25 -25 -15 -2 -23 -10 -23 -23 0 -12
-7 -19 -19 -19 -13 0 -21 -8 -23 -23 -2 -14 -11 -23 -25 -25 -15 -2 -23 -10
-23 -22 0 -12 -8 -20 -22 -22 -15 -2 -24 -11 -26 -26 -3 -16 -10 -22 -28 -22
-17 0 -24 -5 -24 -20 0 -13 -7 -20 -20 -20 -14 0 -20 -7 -20 -24 0 -20 -6 -25
-45 -30 -36 -6 -45 -11 -45 -27 0 -14 -7 -19 -25 -19 -20 0 -25 -5 -25 -25 0
-20 -5 -25 -25 -25 -18 0 -25 -5 -25 -19 0 -15 -10 -20 -45 -25 -36 -6 -45
-11 -45 -27 0 -14 -7 -19 -25 -19 -18 0 -25 -5 -25 -19 0 -16 -9 -21 -45 -27
-35 -5 -45 -10 -45 -25 0 -14 -7 -19 -25 -19 -20 0 -25 -5 -25 -25 0 -23 -4
-25 -45 -25 -38 0 -45 -3 -45 -19 0 -16 -9 -21 -45 -27 -35 -5 -45 -10 -45
-25 0 -14 -7 -19 -25 -19 -18 0 -25 -5 -25 -20 0 -16 -9 -21 -50 -26 -40 -5
-50 -10 -50 -25 0 -15 -8 -19 -40 -19 -34 0 -42 -4 -50 -25 -9 -23 -15 -25
-69 -25 -54 0 -60 -2 -63 -22 -3 -20 -10 -23 -50 -26 -40 -3 -48 -6 -48 -23 0
-15 -7 -19 -32 -19 -69 0 -108 -12 -108 -31 0 -17 -8 -19 -70 -19 -62 0 -70
-2 -70 -19 0 -22 -35 -31 -121 -31 -51 0 -59 -3 -59 -19 0 -16 -11 -19 -95
-26 -84 -6 -95 -9 -95 -26 0 -18 -9 -19 -140 -19 -130 0 -140 -1 -140 -19 0
-27 -70 -31 -556 -31 -435 0 -514 4 -514 25 0 17 -56 25 -177 25 -94 0 -103 2
-103 19 0 15 -11 19 -67 24 -38 4 -89 7 -115 7 -41 0 -48 3 -48 20 0 18 -7 20
-70 20 -62 0 -70 2 -70 19 0 24 -19 31 -86 31 -46 0 -54 3 -54 19 0 19 -39 31
-107 31 -26 0 -33 4 -33 20 0 17 -7 20 -50 20 -42 0 -50 3 -50 19 0 20 -34 31
-96 31 -27 0 -34 4 -34 19 0 15 -10 20 -50 26 -40 6 -50 11 -50 26 0 16 -7 19
-45 19 -41 0 -45 2 -45 25 0 23 -4 25 -45 25 -37 0 -45 3 -45 19 0 15 -10 20
-45 25 -36 6 -45 11 -45 27 0 14 -7 19 -30 19 -25 0 -30 4 -30 25 0 23 -4 25
-44 25 -39 0 -45 3 -48 23 -2 14 -11 23 -26 25 -14 2 -22 10 -22 23 0 15 -7
19 -39 19 -33 0 -40 4 -43 23 -2 14 -11 23 -25 25 -14 2 -23 10 -23 22 0 13
-11 19 -45 24 -36 6 -45 11 -45 27 0 14 -7 19 -25 19 -20 0 -25 5 -25 25 0 20
-5 25 -25 25 -18 0 -25 5 -25 19 0 13 -8 21 -22 23 -15 2 -24 11 -26 25 -3 17
-10 23 -28 23 -17 0 -24 6 -24 19 0 15 -10 21 -40 26 -34 6 -40 11 -40 31 0
19 -5 24 -25 24 -16 0 -25 6 -25 15 0 11 12 15 45 15 38 0 45 -3 45 -20 0 -17
7 -20 45 -20 38 0 45 -3 45 -19 0 -15 10 -20 50 -26 40 -6 50 -11 50 -26 0
-15 7 -19 34 -19 62 0 96 -11 96 -31 0 -16 8 -19 60 -19 54 0 79 -12 48 -22
-7 -3 -5 -7 6 -11 9 -4 15 -3 12 1 -2 4 -1 14 5 22 7 12 9 11 9 -6 0 -22 36
-34 108 -34 25 0 32 -4 32 -20 0 -19 7 -20 95 -20 88 0 95 -1 95 -20 0 -11 3
-21 8 -21 4 -1 65 -4 136 -8 93 -4 130 -10 132 -19 1 -7 0 -8 -3 -2 -8 17 -33
11 -32 -7 0 -12 2 -13 6 -5 3 9 9 10 18 2 17 -14 568 -25 808 -15 174 7 187 8
187 26 0 17 9 19 103 19 137 0 177 7 177 31 0 17 9 19 95 19 88 0 95 1 95 20
0 16 7 20 33 20 67 0 107 12 107 31 0 15 10 19 68 21 61 3 67 5 70 26 3 20 9
22 68 22 56 0 64 2 64 19 0 15 10 20 50 25 41 5 50 10 50 26 0 17 7 20 45 20
38 0 45 3 45 20 0 16 9 21 50 26 39 5 50 11 50 25 0 14 9 19 43 21 35 2 43 7
45 26 3 19 9 22 48 22 40 0 44 2 44 25 0 23 4 25 45 25 38 0 45 3 45 20 0 15
7 20 25 20 20 0 25 5 25 25 0 21 5 25 33 26 17 1 38 1 45 0 7 -1 12 9 12 24 0
20 5 25 25 25 18 0 25 5 25 20 0 15 7 20 25 20 20 0 25 5 25 25 0 23 4 25 45
25 41 0 45 2 45 25 0 20 5 25 25 25 18 0 25 5 25 20 0 13 7 20 20 20 15 0 20
7 20 25 0 20 5 25 25 25 18 0 25 5 25 19 0 13 8 21 23 23 14 2 23 11 25 25 2
14 11 23 25 25 14 2 23 11 25 26 2 14 10 22 23 22 12 0 19 7 19 19 0 13 8 21
23 23 14 2 23 11 25 25 2 14 11 23 25 25 14 2 23 11 25 26 2 14 10 22 23 22
12 0 19 7 19 19 0 13 8 21 23 23 14 2 23 11 25 26 2 14 10 22 23 22 14 0 19 7
19 25 0 20 5 25 25 25 20 0 25 5 25 25 0 18 5 25 19 25 16 0 21 9 27 45 5 35
10 45 25 45 12 0 19 7 19 19 0 13 8 21 23 23 16 2 23 11 25 31 2 18 9 27 22
27 14 0 20 10 25 40 5 30 11 40 26 40 14 0 19 7 19 26 0 17 6 28 19 31 20 5
31 30 31 71 0 15 6 22 19 22 16 0 21 9 27 45 5 35 10 45 25 45 16 0 19 8 19
45 0 41 2 45 25 45 23 0 25 4 25 45 0 38 3 45 19 45 15 0 20 10 26 50 6 40 11
50 26 50 16 0 19 7 19 45 0 37 3 45 19 45 20 0 31 34 31 101 0 31 4 39 19 39
19 0 31 39 31 108 0 25 4 32 20 32 18 0 20 7 20 70 0 68 1 70 25 70 25 0 25 0
25 95 0 86 2 95 19 95 15 0 19 11 24 68 4 37 7 97 7 135 0 60 2 67 20 67 20 0
20 7 20 499 0 494 0 499 -20 494 -14 -4 -20 0 -20 11 0 11 5 15 13 10 10 -6
10 -4 0 7 -7 7 -13 33 -13 59 0 25 -3 75 -7 113 -5 56 -9 67 -24 67 -17 0 -19
9 -19 95 0 95 0 95 -25 95 -25 0 -25 1 -25 90 0 81 -2 90 -19 90 -16 0 -20 11
-27 68 -7 55 -12 67 -27 66 -14 -2 -17 7 -17 52 0 51 -1 54 -25 54 -24 0 -25
2 -25 65 0 57 -2 65 -19 65 -15 0 -20 10 -26 50 -5 39 -11 50 -25 50 -13 0
-19 11 -24 45 -6 36 -11 45 -27 45 -14 0 -19 7 -19 30 0 23 -5 30 -19 30 -16
0 -20 8 -23 43 -2 35 -7 43 -25 45 -20 3 -23 9 -23 48 0 40 -2 44 -25 44 -20
0 -25 5 -25 25 0 18 -5 25 -19 25 -16 0 -21 9 -27 45 -5 35 -10 45 -25 45 -14
0 -19 7 -19 25 0 18 -5 25 -19 25 -15 0 -21 10 -26 40 -5 30 -11 40 -26 40
-14 0 -19 7 -19 25 0 20 -5 25 -25 25 -20 0 -25 5 -25 25 0 18 -5 25 -19 25
-14 0 -21 10 -27 38 -4 20 -6 41 -5 47 2 5 -1 7 -4 3 -13 -12 -45 12 -45 32 0
13 -7 20 -20 20 -13 0 -20 7 -20 19 0 13 -8 21 -22 23 -15 2 -24 11 -26 26 -2
14 -10 22 -22 22 -12 0 -20 8 -22 23 -2 14 -11 23 -25 25 -15 2 -23 10 -23 22
0 12 -8 20 -22 22 -15 2 -24 11 -26 25 -2 14 -11 23 -25 25 -14 2 -23 11 -25
26 -3 16 -10 22 -28 22 -17 0 -24 6 -24 19 0 15 -10 21 -40 26 -30 5 -40 11
-40 26 0 14 -7 19 -25 19 -20 0 -25 5 -25 25 0 20 -5 25 -25 25 -18 0 -25 5
-25 19 0 13 -8 21 -22 23 -15 2 -24 11 -26 26 -3 18 -10 22 -42 22 -32 0 -39
4 -42 23 -2 16 -11 23 -30 25 -19 2 -28 9 -28 22 0 14 -10 20 -40 25 -30 5
-40 11 -40 26 0 14 -7 19 -30 19 -23 0 -30 5 -30 19 0 16 -9 21 -45 27 -34 5
-45 11 -45 25 0 13 -11 19 -45 25 -35 5 -45 10 -45 25 0 16 -8 19 -45 19 -41
0 -45 2 -45 25 0 23 -4 25 -45 25 -38 0 -45 3 -45 19 0 15 -10 20 -50 26 -40
6 -50 11 -50 26 0 17 -8 19 -70 19 -68 0 -70 1 -70 25 0 23 -4 25 -45 25 -38
0 -45 3 -45 20 0 16 -7 20 -37 20 -74 0 -113 11 -113 31 0 17 -9 19 -90 19
-81 0 -90 2 -90 19 0 22 -40 31 -152 31 -70 0 -78 2 -78 19 0 17 -13 19 -185
26 -102 4 -185 11 -185 15 0 4 -3 22 -6 39 l-6 31 53 0 c30 0 84 3 122 7 56 5
67 9 67 24 0 18 11 19 153 19 172 0 227 7 227 26 0 10 22 14 83 17 168 6 316
7 390 1z m-2903 -2559 c0 -37 -3 -45 -19 -45 -24 0 -31 -35 -31 -146 0 -76 -2
-84 -19 -84 -17 0 -20 -11 -26 -100 -4 -55 -11 -97 -16 -95 -7 5 -22 -11 -25
-27 -1 -5 -2 -51 -3 -103 -1 -86 -3 -95 -20 -95 -21 0 -31 -40 -31 -132 0 -51
-2 -58 -20 -58 -18 0 -20 -7 -20 -71 0 -67 1 -70 23 -67 14 2 23 11 25 26 2
14 10 22 23 22 12 0 19 7 19 19 0 15 10 20 45 25 36 6 45 11 45 28 0 11 8 22
20 25 11 3 17 9 14 14 -3 5 0 9 5 9 6 0 10 3 10 8 -6 35 3 52 26 52 19 0 25 5
25 23 0 19 7 25 43 33 23 5 43 15 43 22 1 6 2 23 3 37 1 20 6 25 26 25 20 0
25 5 25 25 0 20 5 25 25 25 18 0 25 5 25 20 0 13 7 20 20 20 14 0 20 7 20 24
0 20 6 25 44 30 36 6 45 11 48 29 2 14 11 23 26 25 14 2 22 10 22 23 0 12 7
19 19 19 13 0 21 8 23 23 2 14 11 23 25 25 14 2 23 11 25 25 2 14 11 23 26 25
14 2 22 10 22 23 0 12 7 19 19 19 13 0 21 8 23 23 2 14 11 23 26 25 14 2 22
10 22 22 0 12 8 20 23 22 14 2 23 11 25 26 2 14 10 22 22 22 12 0 20 8 22 23
2 14 11 23 26 25 14 2 22 10 22 22 0 12 8 20 23 22 14 2 23 11 25 26 2 14 10
22 23 22 12 0 19 7 19 20 0 20 7 20 375 20 l375 0 0 -45 c0 -41 -2 -45 -25
-45 -20 0 -25 -5 -25 -25 0 -18 -5 -25 -20 -25 -13 0 -20 -7 -20 -20 0 -15 -7
-20 -25 -20 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20
0 -13 -7 -20 -20 -20 -15 0 -20 -7 -20 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5
-25 -25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -15
0 -20 -7 -20 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25
-25 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -15 0 -20 -7 -20 -25 0 -20 -5
-25 -25 -25 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20
0 -13 -7 -20 -20 -20 -15 0 -20 -7 -20 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5
-25 -25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -15
0 -20 -7 -20 -25 0 -20 -5 -25 -25 -25 -20 0 -25 -5 -25 -25 0 -20 -5 -25 -25
-25 -18 0 -25 -5 -25 -20 0 -13 -7 -20 -20 -20 -15 0 -20 -7 -20 -25 0 -20 -5
-25 -25 -25 -18 0 -25 -5 -25 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26
-25 -2 -15 -10 -23 -23 -23 -14 0 -19 -7 -19 -25 0 -20 -5 -25 -25 -25 -18 0
-25 -5 -25 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26 -25 -2 -15 -10 -23
-23 -23 -14 0 -19 -7 -19 -25 0 -20 -5 -25 -25 -25 -18 0 -25 -5 -25 -20 0
-13 -7 -20 -19 -20 -27 0 -32 -12 -25 -59 5 -30 11 -41 24 -41 13 0 19 -11 24
-45 6 -36 11 -45 27 -45 14 0 19 -7 19 -25 0 -18 5 -25 19 -25 15 0 20 -10 25
-45 6 -34 12 -45 25 -45 14 0 20 -11 25 -45 6 -36 11 -45 27 -45 14 0 19 -7
19 -25 0 -18 5 -25 19 -25 15 0 20 -10 25 -45 4 -25 11 -45 17 -45 5 0 12 0
17 0 4 0 9 -21 12 -47 4 -40 9 -49 28 -51 16 -3 22 -10 22 -28 0 -17 6 -24 19
-24 16 0 20 -8 23 -42 2 -36 7 -44 26 -46 19 -3 22 -9 22 -48 0 -40 2 -44 25
-44 23 0 25 -4 25 -45 0 -38 3 -45 20 -45 15 0 20 -7 20 -25 0 -20 5 -25 25
-25 23 0 25 -4 25 -44 0 -39 3 -45 23 -48 18 -2 23 -10 25 -45 3 -34 7 -43 22
-43 13 0 20 -8 22 -27 2 -20 9 -29 26 -31 19 -3 22 -9 22 -48 0 -40 2 -44 25
-44 23 0 25 3 25 50 0 42 3 50 19 50 15 0 19 11 24 68 4 37 7 88 7 115 0 40 3
47 20 47 19 0 20 7 20 115 l0 115 25 0 c20 0 25 5 25 25 l0 26 857 -6 c745 -4
858 -7 866 -20 6 -11 26 -15 71 -15 55 0 64 3 69 20 4 17 14 20 71 20 59 0 66
2 66 19 0 13 8 21 23 23 14 2 23 11 25 26 2 13 10 22 22 22 22 0 35 69 25 141
-6 39 -11 49 -26 49 -14 0 -19 7 -19 25 l0 25 -725 0 c-718 0 -725 0 -725 20
0 18 -7 20 -59 20 -86 0 -121 9 -121 31 0 14 -7 19 -25 19 -18 0 -25 5 -25 19
0 16 -9 21 -44 26 -43 7 -44 8 -51 51 -5 35 -10 44 -26 44 -16 0 -19 7 -19 45
0 37 -3 45 -19 45 -16 0 -19 11 -26 82 -3 44 -3 139 0 210 7 116 9 128 26 128
17 0 19 9 19 84 0 108 7 143 31 149 17 5 19 15 19 96 0 83 2 91 19 91 15 0 20
10 25 45 6 36 11 45 27 45 16 0 19 7 19 44 0 39 3 45 23 48 14 2 23 11 25 26
2 14 10 22 23 22 14 0 19 7 19 25 0 20 5 25 25 25 20 0 25 5 25 25 0 18 5 25
20 25 13 0 20 7 20 20 0 15 7 20 24 20 18 0 25 6 28 23 2 14 11 23 26 25 14 2
22 10 22 23 0 13 7 19 25 20 14 1 30 3 35 4 6 1 15 3 20 4 6 0 10 10 10 21 0
17 7 20 39 20 57 0 101 11 101 25 0 7 17 15 38 19 20 3 533 6 1140 6 l1102 0
0 -45 c0 -37 -3 -45 -19 -45 -16 0 -19 -11 -26 -95 -6 -84 -9 -95 -26 -95 -18
0 -19 -9 -19 -115 0 -106 -2 -115 -19 -115 -19 0 -31 -39 -31 -107 l0 -33
-962 0 c-530 0 -980 -3 -1000 -6 -28 -5 -38 -11 -38 -25 0 -14 -7 -19 -25 -19
-18 0 -25 -5 -25 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26 -25 -2 -15 -10
-23 -23 -23 -17 0 -19 -8 -19 -95 0 -95 0 -95 25 -95 20 0 25 -5 25 -25 l0
-25 745 0 c738 0 745 0 745 -20 0 -16 7 -20 33 -20 68 0 107 -12 107 -31 0
-14 7 -19 25 -19 18 0 25 -5 25 -19 0 -13 8 -21 23 -23 14 -2 23 -11 25 -25 2
-15 10 -23 23 -23 14 0 19 -7 19 -25 0 -18 5 -25 19 -25 15 0 20 -10 25 -45 6
-36 11 -45 27 -45 18 0 19 -9 19 -210 0 -198 -1 -210 -19 -210 -22 0 -31 -40
-31 -137 0 -56 -2 -61 -20 -56 -18 5 -19 -1 -22 -108 -3 -110 -4 -114 -25
-117 -20 -3 -23 -9 -23 -48 0 -36 -3 -44 -19 -44 -15 0 -20 -10 -25 -45 -6
-36 -11 -45 -27 -45 -13 0 -19 -7 -19 -24 0 -18 -6 -25 -22 -28 -15 -2 -24
-11 -26 -25 -2 -15 -10 -23 -22 -23 -12 0 -20 -8 -22 -22 -2 -15 -11 -24 -25
-26 -15 -2 -23 -10 -23 -23 0 -14 -7 -19 -25 -19 -20 0 -25 -5 -25 -25 0 -23
-4 -25 -45 -25 -37 0 -45 -3 -45 -19 0 -15 -10 -20 -45 -25 -24 -4 -44 -10
-44 -14 4 -25 -4 -32 -40 -32 -57 0 -101 -11 -101 -25 0 -7 -17 -15 -37 -19
-50 -8 -2081 -8 -2122 0 -21 5 -31 12 -31 25 0 18 -16 19 -419 19 l-418 0 -7
41 c-5 29 -12 43 -26 46 -13 3 -20 14 -20 29 0 17 -6 24 -20 24 -17 0 -20 7
-20 45 0 41 -2 45 -25 45 -23 0 -25 4 -25 44 0 39 -3 45 -22 48 -15 2 -24 11
-26 26 -2 14 -10 22 -22 22 -15 0 -19 9 -22 48 -3 40 -6 47 -25 50 -20 3 -23
9 -23 48 0 36 -3 44 -19 44 -13 0 -20 11 -27 43 -6 27 -15 42 -24 42 -9 0 -16
11 -18 28 -3 21 -9 27 -28 27 -22 0 -24 4 -24 45 0 38 -3 45 -19 45 -13 0 -21
8 -23 23 -2 16 -10 22 -28 22 -18 0 -26 -6 -28 -22 -2 -15 -10 -23 -23 -23
-12 0 -19 -7 -19 -19 0 -13 -8 -21 -22 -23 -15 -2 -24 -11 -26 -25 -2 -14 -11
-23 -25 -25 -14 -2 -23 -11 -25 -25 -2 -15 -10 -23 -23 -23 -12 0 -19 -7 -19
-19 0 -13 -8 -21 -22 -23 -21 -3 -23 -9 -26 -75 -3 -65 -5 -73 -22 -73 -18 0
-20 -7 -20 -77 0 -113 -9 -153 -31 -153 -17 0 -19 -9 -19 -115 l0 -115 -325 0
-325 0 0 185 c0 174 1 185 19 185 21 0 31 40 31 133 0 50 2 57 20 57 18 0 20
7 20 78 0 98 9 152 25 152 17 0 25 47 25 146 0 75 2 84 19 84 22 0 31 40 31
153 0 70 2 77 20 77 18 0 20 7 20 63 0 84 10 137 25 137 17 0 25 46 25 141 0
70 2 79 19 79 22 0 31 40 31 153 0 70 2 77 20 77 18 0 20 7 20 63 0 84 10 137
25 137 17 0 25 47 25 146 0 75 2 84 19 84 15 0 19 11 24 68 4 37 7 88 7 115 0
40 3 47 20 47 19 0 20 7 20 90 l0 90 350 0 350 0 0 -45z"/>
<path d="M4101 8964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4131 8964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4005 8913 c2 -16 6 -36 10 -45 10 -25 -23 -30 -138 -24 -54 3 -99 1
-102 -4 -3 -5 -6 -26 -7 -47 -1 -43 -15 -76 -27 -68 -5 3 -11 -1 -14 -8 -4
-10 -31 -12 -121 -9 l-116 5 0 -71 0 -71 -72 -3 c-69 -3 -73 -4 -70 -25 3 -22
0 -23 -61 -23 -61 0 -65 -2 -71 -25 -6 -23 -10 -25 -71 -25 l-65 0 0 -45 0
-45 -62 0 c-45 0 -59 -3 -48 -10 12 -8 13 -11 1 -19 -11 -7 -11 -9 0 -14 10
-4 9 -6 -3 -6 -9 -1 -20 -10 -23 -21 -5 -16 -15 -20 -55 -20 l-49 0 -3 -52
c-2 -44 -6 -52 -20 -50 -12 2 -18 -4 -18 -18 0 -21 -16 -25 -72 -21 l-28 2 0
-70 0 -71 -70 0 c-68 0 -70 -1 -70 -25 0 -18 -5 -25 -19 -25 -15 0 -20 -10
-26 -46 -5 -39 -9 -45 -26 -41 -15 4 -19 0 -19 -24 0 -24 -4 -29 -25 -29 -20
0 -25 -5 -25 -26 0 -20 -4 -24 -19 -19 -16 5 -20 -1 -26 -41 -5 -34 -12 -48
-26 -51 -10 -3 -19 -14 -19 -24 0 -13 -8 -19 -25 -21 -74 -4 -107 -6 -130 -7
-23 -1 -24 -5 -27 -68 -3 -62 -5 -68 -25 -71 -15 -2 -23 -10 -23 -23 0 -24
-17 -25 -23 -1 -4 16 -5 16 -6 0 0 -10 -5 -18 -11 -18 -6 0 -10 -36 -10 -89 0
-85 -1 -90 -22 -93 -15 -2 -24 -11 -26 -25 -2 -15 -11 -23 -30 -25 -25 -3 -26
-6 -24 -49 4 -49 3 -50 -60 -49 -22 0 -28 -4 -28 -20 0 -13 -7 -20 -19 -20
-21 0 -31 -19 -31 -57 0 -16 -7 -27 -20 -30 -18 -5 -20 -14 -20 -75 0 -64 -2
-69 -19 -63 -11 3 -22 1 -26 -5 -3 -5 -15 -10 -26 -10 -13 0 -19 -7 -20 -22
-4 -115 -3 -113 -26 -116 -15 -2 -23 -10 -23 -23 0 -12 -7 -19 -19 -19 -15 0
-20 -10 -25 -45 -6 -36 -11 -45 -27 -45 -21 0 -20 1 -23 -80 -1 -49 -3 -55
-23 -58 -23 -3 -23 -6 -23 -143 0 -129 -1 -139 -19 -139 -10 0 -21 9 -24 20
-3 11 -13 20 -24 19 -11 0 -13 -3 -5 -6 19 -7 -16 -32 -45 -33 -21 0 -23 -5
-23 -49 0 -42 3 -49 22 -54 12 -4 19 -9 15 -13 -4 -4 -2 -13 3 -19 6 -7 7 -32
4 -59 -5 -36 -11 -46 -25 -46 -17 0 -19 -10 -20 -122 0 -68 -4 -132 -9 -143
-7 -17 -9 -17 -9 -2 -1 9 -5 17 -10 17 -12 0 -21 -37 -21 -95 0 -36 -3 -46
-10 -35 -8 13 -10 12 -10 -2 0 -10 -4 -18 -10 -18 -6 0 -10 -46 -10 -119 0
-96 -3 -121 -16 -132 -13 -10 -17 -10 -25 2 -5 9 -9 -15 -9 -68 0 -74 -2 -83
-19 -83 -12 0 -20 -9 -24 -28 -5 -26 1 -133 8 -140 2 -2 21 -6 43 -9 39 -5 40
-6 40 -49 1 -40 -1 -44 -23 -44 -23 0 -25 -4 -25 -49 0 -59 -7 -81 -26 -81
-13 0 -16 -14 -18 -80 -1 -15 5 -26 14 -26 8 -1 19 -2 23 -3 5 0 1 -8 -8 -17
-9 -9 -13 -19 -10 -22 3 -3 11 3 18 13 13 16 13 80 2 112 -3 8 5 12 20 11 14
-1 25 -7 26 -13 4 -57 3 -68 -8 -72 -7 -3 -13 -13 -13 -23 0 -11 -5 -22 -10
-25 -6 -3 -10 -40 -10 -81 0 -41 4 -73 9 -70 4 3 17 -1 27 -9 28 -21 -4 -20
-34 1 -27 19 -52 12 -47 -13 2 -10 4 -28 4 -40 1 -16 7 -23 20 -23 12 0 21 -8
23 -22 2 -11 11 -22 21 -24 11 -1 21 -4 23 -7 2 -2 4 -117 5 -256 l0 -251 38
0 c26 0 41 -5 45 -16 3 -9 6 -97 6 -196 0 -157 2 -179 15 -168 18 15 35 5 35
-21 0 -28 38 -25 42 4 2 14 10 22 23 22 19 0 20 -8 23 -132 l3 -133 -23 -1
c-18 -1 -15 -3 12 -11 19 -6 29 -13 23 -15 -21 -8 -15 -20 12 -27 22 -6 25
-12 25 -51 0 -39 3 -45 21 -45 19 0 20 -5 17 -70 l-3 -70 28 0 c15 0 27 -4 27
-10 0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 5 -1 10 4 10 16 0 14 -127 -1 -133 -8
-3 -6 -6 5 -6 11 -1 17 -9 17 -26 0 -18 5 -25 20 -25 17 0 20 -7 20 -45 0 -41
2 -45 25 -45 24 0 25 -2 25 -69 l0 -70 53 -3 c48 -3 52 -5 50 -26 -2 -17 2
-22 15 -20 14 2 18 -6 20 -44 3 -40 6 -48 23 -48 18 0 20 -6 17 -42 -2 -40 -1
-43 26 -48 28 -5 50 -30 26 -30 -6 0 -9 -4 -5 -9 3 -5 17 -8 30 -5 23 4 25 1
25 -34 0 -21 -5 -43 -12 -50 -9 -9 1 -12 45 -12 l57 0 1 -72 c1 -94 3 -106 28
-132 12 -12 21 -34 21 -49 0 -25 3 -27 45 -27 41 0 45 -2 45 -25 0 -23 4 -25
46 -25 41 0 45 -2 39 -20 -5 -17 -1 -21 24 -26 21 -5 31 -12 31 -25 0 -14 7
-19 25 -19 23 0 25 -4 25 -44 0 -39 3 -45 23 -48 14 -2 23 -11 25 -25 2 -15
10 -23 23 -23 14 0 19 -7 19 -29 0 -29 21 -57 34 -44 4 4 11 1 16 -7 8 -13 10
-12 10 2 0 11 -10 18 -27 21 -16 2 2 5 39 6 l67 1 -2 -27 c-2 -23 2 -28 20
-26 19 2 23 -4 29 -42 l6 -45 44 0 c37 0 44 -3 44 -19 0 -13 8 -21 23 -23 14
-2 23 -11 25 -25 2 -15 10 -23 23 -23 13 0 19 -7 19 -21 0 -12 8 -23 20 -26
10 -3 22 -13 25 -23 4 -13 19 -20 53 -24 37 -4 196 -2 270 3 6 1 12 10 12 21
0 15 -7 20 -25 20 -20 0 -25 5 -25 25 0 18 -5 25 -19 25 -16 0 -21 9 -26 44
-6 36 -12 46 -29 48 -13 2 -22 11 -24 26 -2 14 -10 22 -23 22 -12 0 -19 7 -19
19 0 13 -8 21 -22 23 -15 2 -24 11 -26 25 -3 18 -12 23 -48 29 l-44 6 0 44 c0
37 -3 44 -19 44 -13 0 -21 8 -23 23 -2 14 -11 23 -25 25 -20 3 -23 9 -23 48 0
40 -2 44 -25 44 -18 0 -25 5 -25 19 0 13 -8 21 -22 23 -15 2 -24 11 -26 26 -2
14 -10 22 -22 22 -15 0 -20 10 -26 45 -5 34 -10 45 -23 43 -11 -2 -17 6 -19
25 -3 24 -7 27 -43 27 -21 0 -39 3 -40 8 0 4 -3 16 -5 27 -2 11 -5 28 -6 37
-2 12 -11 18 -26 18 -18 0 -23 5 -22 23 1 12 2 43 1 69 -1 40 -4 47 -23 50
-15 2 -23 10 -23 23 0 13 -8 21 -22 23 -20 3 -23 10 -23 43 0 23 -7 47 -15 56
-8 8 -13 19 -10 24 4 5 -1 9 -9 9 -9 0 -16 12 -18 33 -2 23 -8 32 -20 31 -10
-1 -17 1 -16 5 2 21 -4 31 -21 31 -20 0 -31 25 -31 71 0 18 -6 28 -20 32 -14
3 -21 17 -26 46 -5 30 -11 41 -24 41 -14 0 -20 11 -25 50 -4 27 -11 53 -17 56
-6 4 -5 10 3 15 11 7 10 9 -3 9 -9 0 -19 -6 -21 -12 -3 -7 -6 4 -6 24 -1 20
-5 48 -11 62 -8 22 -16 26 -47 26 -37 0 -38 1 -40 42 -3 47 -10 59 -23 38 -11
-18 -19 13 -22 90 -3 47 -6 55 -23 55 -14 0 -21 8 -23 28 -2 19 -9 27 -22 27
-12 0 -20 8 -22 23 -2 14 -11 23 -25 25 -14 2 -22 10 -21 20 1 9 0 50 0 90 -2
70 -3 72 -27 72 -20 0 -25 5 -25 25 0 18 -5 25 -19 25 -13 0 -21 8 -23 23 -2
14 -11 23 -25 25 -17 3 -23 10 -23 28 0 13 5 24 11 24 5 0 7 -6 4 -12 -4 -7 1
-4 10 7 26 32 15 50 -28 47 -34 -3 -37 -1 -38 22 -1 14 -3 35 -4 46 -1 11 -3
45 -4 76 -1 49 -3 55 -20 49 -16 -5 -19 2 -24 62 -4 38 -7 98 -7 136 0 60 -2
67 -20 67 -12 0 -17 5 -14 13 2 7 1 31 -4 52 -6 28 -13 40 -25 39 -9 -1 -17 5
-17 14 0 8 3 13 6 9 10 -10 34 20 29 36 -7 17 20 41 35 32 7 -4 10 27 10 95 0
88 -2 101 -16 98 -10 -2 -18 3 -20 10 -1 7 0 8 3 2 9 -19 23 1 17 26 -4 14 -2
24 5 24 6 0 8 12 4 32 -4 19 -2 29 4 25 6 -4 9 31 9 96 -1 98 -2 102 -23 105
-19 3 -23 10 -23 42 0 32 4 39 23 42 21 3 22 6 24 138 1 74 3 140 3 145 1 6 1
22 0 37 -1 20 4 27 22 31 l23 4 -22 2 c-25 1 -33 21 -9 21 17 0 30 30 13 30
-7 0 -4 5 6 11 10 6 27 9 37 6 12 -3 17 0 14 8 -3 7 -14 11 -25 8 -13 -4 -19
0 -19 11 0 10 9 16 24 16 l25 0 0 189 c1 194 4 221 26 221 14 0 25 105 25 247
0 81 2 93 18 94 71 5 80 10 57 29 -11 9 -15 9 -15 1 0 -7 -4 -10 -8 -7 -5 3
-7 19 -4 36 3 21 10 30 23 30 16 0 19 8 20 58 1 31 1 74 -1 95 -2 35 0 38 19
32 17 -5 21 -2 21 14 0 14 -6 21 -20 21 -11 0 -20 5 -20 11 0 6 -5 7 -12 2
-24 -15 -36 -11 -30 9 3 12 13 23 23 25 9 3 20 16 24 30 3 14 9 23 12 20 3 -4
15 0 25 8 11 7 27 11 34 8 8 -3 14 1 14 10 0 8 -8 17 -18 20 -11 2 -21 17 -25
33 -6 32 5 160 15 170 15 15 28 -11 28 -55 0 -27 3 -69 6 -95 7 -46 7 -46 45
-46 l39 0 0 50 c0 38 -4 50 -15 50 -8 0 -15 8 -16 18 0 14 -2 14 -9 -3 -5 -11
-9 -15 -9 -10 -1 6 -6 3 -11 -5 -8 -13 -10 -13 -10 1 0 22 49 47 59 30 10 -15
21 7 21 41 0 15 5 19 20 15 17 -4 20 0 20 23 0 24 -5 29 -27 32 -16 2 -47 0
-71 -3 -59 -8 -94 -1 -90 20 2 9 13 20 26 26 13 6 23 19 23 32 1 13 2 29 3 36
0 6 11 13 24 15 20 3 22 8 22 73 l0 69 45 0 c51 0 61 12 46 53 -8 19 -6 28 5
38 8 6 17 9 21 5 3 -3 1 -6 -5 -6 -21 0 -13 -36 8 -37 15 -1 19 4 16 20 -4 30
13 100 27 109 7 4 22 8 34 8 18 0 23 -7 28 -45 8 -50 31 -78 46 -54 5 8 9 21
10 29 0 10 3 8 9 -5 8 -19 9 -19 9 3 1 12 -3 22 -9 22 -11 0 -14 78 -4 104 5
12 10 13 26 5 19 -10 20 -8 16 33 l-3 43 47 3 c42 3 47 6 50 30 2 19 8 26 20
24 11 -2 18 5 20 20 2 15 10 23 23 23 23 0 24 4 27 105 l3 75 43 3 c34 3 42 7
42 23 0 14 7 19 25 19 23 0 25 3 25 50 0 43 3 50 20 50 13 0 20 7 20 20 0 16
7 20 30 20 17 0 30 4 30 10 0 5 8 7 18 4 16 -5 16 -5 0 9 -10 8 -18 17 -18 21
0 4 8 6 18 4 12 -2 18 5 20 25 3 21 9 27 28 27 24 0 25 3 28 64 1 18 20 26 69
27 11 0 17 8 17 25 0 18 5 24 23 24 23 0 62 20 40 20 -7 0 -13 5 -13 10 0 6
11 10 25 10 22 0 25 4 25 35 0 19 5 35 11 35 5 0 7 -4 4 -10 -3 -5 1 -10 10
-10 11 0 15 7 13 23 -2 18 2 23 25 25 19 2 27 9 27 22 0 12 8 20 23 22 14 2
23 11 25 26 2 14 10 22 23 22 12 0 19 7 19 20 0 11 1 20 3 21 1 0 36 3 77 6
41 3 78 10 82 17 6 8 8 8 8 0 0 -7 11 -11 25 -10 23 1 24 2 8 14 -17 12 -16
13 10 16 6 0 13 13 15 29 3 21 9 27 28 27 17 0 24 6 24 19 0 20 34 31 101 31
37 0 39 2 40 33 1 17 1 35 0 40 0 4 6 7 15 7 8 0 12 -4 9 -10 -3 -5 -2 -10 4
-10 5 0 11 17 13 38 3 34 6 37 36 40 25 3 32 7 28 19 -4 9 1 17 12 20 10 2 22
14 27 26 9 19 19 22 73 25 59 3 62 4 62 28 0 20 5 25 23 24 51 -5 67 0 67 19
0 12 9 21 25 25 16 4 25 13 25 26 0 14 7 20 23 20 20 0 22 5 25 70 l3 70 -74
0 -74 0 2 -27z m32 -55 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-2007 -1938 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m-34 -40 c8 0 11 -6 8 -15 -7 -18 -34 -20 -34 -2 0 22 -20 -16 -20 -39 0
-13 -8 -24 -20 -27 -18 -5 -20 0 -20 37 0 52 6 59 44 52 16 -3 35 -6 42 -6z
m-89 -262 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-287 -27 c0
-6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m274
-124 c7 -6 6 -7 -4 -4 -8 2 -16 12 -17 23 -2 13 -1 15 4 4 3 -8 11 -19 17 -23z
m-210 -72 c8 -11 18 -15 26 -10 10 6 9 9 -1 16 -16 10 -7 11 76 14 56 1 75
-11 75 -49 0 -18 -4 -24 -15 -20 -14 6 -21 -11 -16 -39 0 -4 2 -23 4 -42 1
-19 6 -35 11 -35 4 0 6 9 3 20 -3 11 -1 18 4 14 12 -7 11 -41 -1 -49 -6 -4 -8
-11 -5 -16 4 -5 2 -9 -4 -9 -16 0 -25 41 -24 117 1 46 -3 71 -9 67 -5 -4 -8
-17 -5 -31 3 -16 -2 -27 -15 -34 -25 -13 -34 -6 -13 11 18 15 20 41 3 58 -9 9
-9 12 2 12 7 0 11 2 8 5 -6 6 -65 -23 -73 -36 -10 -15 -23 -10 -30 11 -5 16
-12 19 -32 14 -18 -5 -24 -3 -19 5 4 6 2 11 -3 11 -6 0 -11 4 -11 9 0 16 50 5
64 -14z m-63 -90 c5 4 9 1 9 -4 0 -6 -9 -11 -20 -11 -22 0 -23 1 -13 27 7 17
8 17 11 0 2 -9 8 -15 13 -12z m289 -4 c0 -6 -9 -9 -20 -8 -11 1 -20 5 -20 9 0
4 9 8 20 8 11 0 20 -4 20 -9z m-190 -91 c0 -13 -7 -20 -20 -20 -13 0 -20 7
-20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m37 -12 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m-369 -351 c-1 -13 -8 -24 -15 -24 -7 0 -13 11 -13
24 0 14 6 23 15 23 9 0 14 -8 13 -23z m405 -108 c-9 -9 -12 -7 -10 9 1 14 5
18 11 12 7 -7 6 -14 -1 -21z m-259 -141 c10 -38 7 -78 -6 -79 -10 0 -10 -2 0
-6 6 -2 12 -12 12 -20 0 -11 7 -14 24 -9 16 4 30 0 40 -10 15 -14 16 -14 17 3
1 17 1 17 6 0 3 -11 -3 -24 -14 -32 -17 -14 -17 -14 1 -15 20 0 20 0 0 -15
-10 -8 -37 -14 -59 -13 l-40 1 45 7 45 7 -40 2 c-22 1 -45 5 -51 9 -14 8 -29
142 -19 171 8 28 31 27 39 -1z m226 -310 c0 -10 -7 -18 -15 -18 -8 0 -15 8
-15 18 0 10 7 19 15 19 8 0 15 -9 15 -19z m-100 -754 c0 -8 -4 -12 -10 -9 -5
3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m0 -308 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-373 -258 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1805 6400 c-3 -5 1 -10 9 -10 9 0 16 -7 16 -15 0 -8 5 -15 10 -15
15 0 12 19 -4 36 -17 17 -23 17 -31 4z"/>
<path d="M3731 8804 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2340 7710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2010 7329 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M2242 7138 c2 -6 10 -13 16 -14 7 -1 8 0 2 3 -5 2 -8 9 -4 14 3 5 0
9 -6 9 -7 0 -10 -5 -8 -12z"/>
<path d="M2310 7040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2001 6734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2262 2634 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z"/>
<path d="M1691 2624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2350 2451 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M6158 438 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M7129 4813 c-1 -15 -1 -47 0 -69 2 -37 0 -42 -23 -48 -26 -6 -38 -38
-13 -35 28 4 37 -2 37 -26 0 -18 5 -25 20 -25 19 0 20 -7 20 -127 0 -71 3
-145 6 -165 6 -33 10 -38 33 -38 14 0 33 -5 41 -10 13 -9 13 -11 0 -20 -11 -7
-12 -10 -2 -10 8 0 16 9 19 19 3 11 17 21 36 25 18 4 28 10 22 14 -16 11 -12
77 5 77 22 0 28 38 29 176 1 111 3 127 17 125 9 0 19 5 21 12 3 7 -4 12 -16
12 -15 0 -21 6 -21 24 0 26 -29 59 -31 35 -1 -8 -4 -3 -8 11 -5 18 -15 26 -34
28 -22 3 -27 7 -22 21 5 16 -2 18 -65 20 l-70 2 -1 -28z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
